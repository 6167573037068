.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.song-detail_out {
  width: 1020px;
  margin: 50px auto 0;
}

/* --------------- Head --------------- */
.song-detail_head {
  display: flex;
  align-items: flex-start;
  background-color: #f6f6f6;
  margin-bottom: 30px;
}

.song-cover-wrap {
  width: 280px;
  height: 280px;
  flex-shrink: 0;
  margin: 0 20px;
  position: relative;
  align-self: center;
}

.song-detail_head .song-cover-wrap > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.song-detail_singer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.song-detail_singer.edit {
  margin: 30px 20px 30px 0px;
}

.song-detail_singer h1 {
  font-size: 42px;
  font-weight: 900;
  padding: 20px 0;
  line-height: 1.2;
}

.song-detail_singer p {
  margin-bottom: 20px;
  padding-right: 20px;
  font-size: 15px;
}

/* info */
.song-detail_info {
  display: flex;
  margin-top: auto;
  padding-bottom: 20px;
}

.song-detail_info li {
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #999;
}

.song-detail_info svg {
  margin-right: 5px;
}

.song-detail_info img {
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.song-detail_info a {
  font-weight: 700;
  color: #333;
}

.song-detail_info a:hover {
  color: var(--pri-color);
}

/* Btns */
.song-detail_btns {
  display: flex;
}

.song-detail_btns li {
  margin-right: 10px;
}

.song-detail_btns button,
.publish-button-list button {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  height: 50px;
  width: 150px;
  cursor: pointer;
  font-size: 18px;
}

.song-detail_btns .song-detail_play,
.publish-button-list .song-detail_publish {
  background-color: var(--pri-color);
  color: white;
  border: 0;
}

.song-detail_btns svg,
.publish-button-list svg {
  margin-right: 10px;
}

/* --------------- More --------------- */
.song-detail_more {
  margin-bottom: 30px;
}

.song-detail_more h3 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  margin-top: 20px;
}

.song-detail_more ul {
  display: flex;
}

.song-detail_more li {
  margin-right: 30px;
}

.song-detail_more .song-detail_more_wrap {
  text-align: center;
  position: relative;
  cursor: pointer;
}

.song-detail_more .song-detail_more_img {
  width: 180px;
  height: 180px;
  margin-bottom: 10px;
  position: relative;
  background-color: #f6f6f6;
  text-align: center;
}

.song-detail_more img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.song-detail_more svg {
  display: none;
  line-height: 0.5;
  position: absolute;
  left: 90px;
  top: 90px;
  transform: translate(-50%, -50%);
  font-size: 72px;
  color: var(--pri-color);
  background-image: radial-gradient(#fff 20%, transparent);
  border-radius: 50%;
  z-index: 10;
}
.song-composer-info {
  font-weight: 700;
  color: #333;
  cursor: pointer;
}
.song-composer-info:hover {
  color: #c30d22;
}
.song-detail_more .song-detail_more_wrap:hover svg {
  display: block;
}

/* --------------- Comments --------------- */
.comments-text {
  padding-top: 30px;
  padding-bottom: 20px;
}

.song-detail_comments h3 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 20px;
}

.comments-text form {
  display: flex;
}

.comments-text form div {
  background-color: #f0f2f5;
  border-radius: 30px;
  padding: 10px 15px;
  outline: 0;
  margin-right: 10px;
  flex-grow: 1;
  caret-color: auto;
}

.comments-text div[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-text);
  color: #999;
}

.comments-text button {
  background-color: var(--pri-color);
  color: white;
  border: 0;
  border-radius: 50px;
  height: 45px;
  padding: 0 20px;
}

.song-detail_comments li {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
}

.song-detail_comments .comments-head {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  float: left;
}

.song-detail_comments img {
  max-width: 30px;
  max-height: 30px;
}

.song-detail_comments h4 {
  font-weight: 700;
  margin-left: 40px;
}

.song-detail_comments p {
  margin-left: 40px;
}

.song-detail_comments span {
  position: absolute;
  right: 0;
  top: 20px;
  font-size: 13px;
  color: #ccc;
}
/* publish list */
.publish-button-list {
  display: flex;
  justify-content: center;
}
.publish-button-list li {
  margin-right: 10px;
}

.song-title_input {
  display: block;
  width: 600px;
  height: 60px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 42px;
  font-weight: 900;
  outline: none;
}
.song-bio_input {
  display: block;
  width: 600px;
  height: 100px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  outline: none;
}

.cover-img_editmask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.song-cover-wrap:hover label {
  display: flex;
}

.cover-img_editmask svg {
  color: white;
  font-size: 100px;
}

.cover-img_editmask ~ input {
  display: none;
}
/* upload new music file */
.upload-new-music-file {
  display: flex;
}
.file-upload-btn {
  display: block;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  background-color: var(--pri-color);
  color: white;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
.music-file-title-col {
  color: black;
}
.music-format-col {
  color: #ccc;
}
.music-file-title-col,
.music-format-col {
  line-height: 50px;
}

@media screen and (max-width: 768px) {
  .song-detail_out {
    width: auto;
  }
  .song-detail_head {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .song-detail_singer h1 {
    text-align: center;
    font-size: 24px;
    padding: 20px;
  }
  .song-detail_singer p {
    margin-bottom: 0;
    padding: 0 20px 20px 20px;
    text-align: center;
  }
  .song-detail_info {
    flex-wrap: wrap;
    padding: 20px;
    justify-content: center;
  }
  .song-detail_btns {
    justify-content: center;
    flex-direction: column;
  }
  .song-detail_btns li {
    margin: 0 5px;
    /* flex-basis: 30%; */
  }
  .song-detail_btns button {
    width: 100%;
    font-size: 14px;
    white-space: nowrap;
    color: #333;
  }
  .song-detail_singer.edit {
    box-sizing: border-box;
    margin: 20px;
  }
  .song-detail_singer .song-title_input,
  .song-detail_singer .song-bio_input {
    box-sizing: border-box;
    width: 100%;
  }
  .song-detail_btns {
    flex-wrap: wrap;
  }
  ul.song-detail_btns li {
    margin: 0 5px 10px;
  }
  ul.song-detail_btns li:nth-child(3) {
    flex-basis: calc(100% - 22px);
  }
  .upload-new-music-file {
    flex-direction: column;
  }
  .file-upload-btn {
    display: block;
    align-self: center;
    width: 100%;
    margin-right: 0px;
  }
  .publish-button-list {
    flex-wrap: wrap;
  }
  .publish-button-list .song-detail_publish-list {
    margin: 20px 5px 0 5px;
    width: 100%;
  }
  .publish-button-list .song-detail_publish {
    width: 100%;
  }
}
