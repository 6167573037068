/* -------------- Global Popup -------------- */
.popup-mask {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.popup {
  max-width: 80%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 30px;
}

.popup svg {
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 20px;
  cursor: pointer;
}

.popup h1 {
  line-height: 50px;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
}

.popup p {
  text-align: center;
  margin-bottom: 30px;
}

.popup button {
  display: block;
  background-color: var(--pri-color);
  border: 0;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .popup {
    transform: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-height: 250px;
    max-height: 250px;
  }
}
