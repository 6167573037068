.crop-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.crop-modal {
  padding: 1px 0 40px 0;
  margin: 100px auto 0px;
  background-color: #fff;
  border-radius: 10px;
  width: 50%;
}
.crop-area {
  width: 80%;
  /* height: 80%; */
  margin: 35px auto 0;
  background-color: #ccc;
  border-radius: 5px;
}
.button-list {
  display: flex;
  margin: 20px 0 0 0;
  flex-direction: row;
  justify-content: center;
}
.button-list button {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  height: 50px;
  width: 150px;
  cursor: pointer;
  font-size: 18px;
  margin: 0 20px 0 0;
}

.button-list button.button-confirm:hover {
  background-color: var(--pri-color);
  color: white;
  border-color: var(--pri-color);
}
