.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.user-out {
  width: 1020px;
  margin: 50px auto;
}

/* -------------- Head Block ------------- */
.user-head {
  display: flex;
  padding-bottom: 30px;
}
.user-head_imgInner {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-right: 30px;
  overflow: hidden;
  position: relative;
  background-color: #f6f6f6;
}
.user-head_imgInner img {
  max-width: 300px;
  max-height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-head_info {
}

.user-head_info h1 {
  font-size: 42px;
  font-weight: 900;
  line-height: 2;
}

.user-head_info p {
  margin-bottom: 20px;
}

.user-head_info ul {
  display: flex;
  margin-bottom: 30px;
}

.user-head_info ul li {
  color: #999;
  margin-right: 20px;
}

.user-head_info img {
  height: 30px;
}

.user-head_info ul li svg {
  margin-right: 5px;
}

.user-head_info ol {
  display: flex;
}

.user-head_info ol li {
  margin-right: 10px;
}

.user-head_info ol button {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  height: 50px;
  width: 150px;
  cursor: pointer;
  font-size: 18px;
}

.user-head_info ol li svg {
  margin-right: 10px;
}

.user-head_info ol button:hover {
  background-color: var(--pri-color);
  color: white;
  border-color: var(--pri-color);
}

/* -------------- Tracks Block ------------- */
.user-tracks {
}

.user-tracks h3 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 20px;
}

.user-tracks ul {
}

.user-tracks ul li {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
}

.user-tracks ul li:hover {
  background-color: #f6f6f6;
}

.user-tracks img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-tracks h2 {
  flex-basis: 0;
  flex-grow: 3;
}

.user-tracks p {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.user-tracks span {
  color: #999;
  flex-grow: 1;
  text-align: right;
}

.user-tracks_cover {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  position: relative;
}

.user-tracks_cover svg {
  border-radius: 50%;
  line-height: 28px;
  color: var(--pri-color);
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 28px;
  transform: translate(-50%, -50%);
  background-image: radial-gradient(#fff, transparent);
  display: none;
}

.user-tracks ul li:hover svg {
  display: block;
}

.user-img_edit {
  flex: 0 0 300px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
  position: relative;
}

.user-img_edit img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: auto;
}

.user-img_editmask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-img_edit:hover label {
  display: flex;
}

.user-img_editmask svg {
  color: white;
  font-size: 100px;
}

.user-img_editmask ~ input {
  display: none;
}
/* input edit column */

.user-head_info h1 {
  font-size: 42px;
  font-weight: 900;
  line-height: 2;
  position: relative;
}

.user-edit_icon {
  width: 26px;
  height: 26px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.user-head_info p {
  margin-bottom: 20px;
  position: relative;
}

.user-head_info p b {
  top: -20px;
}

.user-head_info ul {
  display: flex;
  margin-bottom: 30px;
}

.user-head_info ul li {
  color: #999;
  margin-right: 20px;
}

.user-head_info ul li svg {
  margin-right: 5px;
}

.user-head_info img {
  height: 30px;
}

.user-head_info ol {
  display: flex;
}

.user-head_info ol li {
  margin-right: 10px;
}

.user-head_info ol button {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  height: 50px;
  width: 150px;
  cursor: pointer;
  font-size: 18px;
}

.user-head_info ol li svg {
  margin: 5px 10px 0 0;
  font-size: 18px;
}

.user-head_info ol button:hover {
  background-color: var(--pri-color);
  color: white;
  border-color: var(--pri-color);
}
/* -------------- User Edit ------------- */
.user-name_input {
  display: block;
  width: 600px;
  height: 60px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 42px;
  font-weight: 900;
}

.user-name_input::placeholder {
  position: relative;
  top: -8px;
}

.user-bio_input {
  display: block;
  width: 600px;
  height: 100px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
}

@media screen and (max-width: 768px) {
  .user-out {
    width: auto;
  }
  .user-head_info h1 {
    text-align: center;
  }
  .user-head_info p {
    text-align: center;
  }
  .user-head {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .user-head_info ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .user-head_info ol {
    flex-wrap: wrap;
    justify-content: center;
  }
  .user-head_info ol button {
    width: 100%;
    font-size: 14px;
    color: #333;
  }
  .user-head_info ol li {
    margin: 0 5px 10px;
    width: 30%;
  }
  .user-head_info ol li:nth-child(4) {
    flex-basis: calc(100% - 22px);
  }
  .user-head_info ol li:nth-child(4) button {
    width: 100%;
  }
  .user-head_info input,
  .user-head_info textarea {
    margin: 10px;
    box-sizing: border-box;
    width: 100%;
  }
}
