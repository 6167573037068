.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.top {
  height: 60px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  position: fixed;
  left: 250px;
  top: 0;
  width: calc(100% - 250px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
}

.top-login {
  padding-right: 20px;
  display: flex;
}

.top-login .top-userhead {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.top-login .top-userhead img {
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.top-upload {
  background-color: var(--pri-color);
  color: white;
  padding: 0 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.top-upload img {
  margin-right: 10px;
}

.composer-out {
  width: 1020px;
  margin: 0 auto;
}

.composer-out h1 {
  font-size: 52px;
  line-height: 3;
}

.composer-main {
  display: flex;
}

/* File upload */
.file-upload-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.file-upload-btn {
  display: block;
  padding: 0 20px;
  height: 40px;
  white-space: nowrap;
  background-color: var(--pri-color);
  color: white;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.file-upload-block p {
  width: calc(60% - 10px);
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  color: #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Image upload */
.img-upload-block {
  margin-right: 20px;
  width: 400px;
}

.img-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  border: 1px dashed #e5e5e5;
  background-color: #f6f6f6;
  text-align: center;
  color: #999;
  cursor: pointer;
}

.img-upload i {
  font-size: 98px;
  color: #ccc;
  line-height: 1;
}

.img-upload img {
  max-width: 100%;
  max-height: 100%;
}

/* ------------------- Composer Data ------------------- */
.composer-data {
  flex-grow: 1;
  padding-top: 20px;
}

.composer-data h3 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
}

.composer-data input[type="text"] {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
}

.composer-data textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
}

.composer-send {
  background-color: var(--pri-color);
  height: 50px;
  padding: 0 50px;
  color: white;
  border-radius: 5px;
  border: 0;
  display: block;
  margin: 50px auto 0;
  cursor: pointer;
}
/* range css */
.up-copyright {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.up-copyright p {
  flex-basis: 100%;
}

.up-copyright h4 {
  text-align: center;
  font-size: 24px;
  line-height: 1;
  margin-left: 10px;
}

.up-copyright h4:after {
  content: "%";
  padding-left: 1px;
}
.up-copyright input[type="range"] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 85%;
  max-width: 85%;
  margin: 10px 0 0 0;
  transition: box-shadow 0.2s ease-in-out;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .up-copyright input[type="range"] {
    overflow: hidden;
    height: 20px;
    -webkit-appearance: none;
    background-color: #ddd;
  }
  .up-copyright input[type="range"]::-webkit-slider-runnable-track {
    height: 20px;
    -webkit-appearance: none;
    color: #444;
    margin-top: -1px;
    transition: box-shadow 0.2s ease-in-out;
  }
  .up-copyright input[type="range"]::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    cursor: ew-resize;
    background: #fff;
    box-shadow: -340px 0 0 330px #1597ff, inset 0 0 0 40px #1597ff;
    border-radius: 50%;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }
  .up-copyright input[type="range"]:active::-webkit-slider-thumb {
    background: #fff;
    box-shadow: -340px 0 0 320px #1597ff, inset 0 0 0 3px #1597ff;
  }
}
.up-copyright input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}
.up-copyright input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}
.up-copyright input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}
.up-copyright input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

.img-upload svg {
  font-size: 98px;
  color: #ccc;
  line-height: 1;
}

.file-title {
  color: black;
}
@media screen and (max-width: 768px) {
  .composer-out {
    width: auto;
  }
  .composer-out h1 {
    font-size: 32px;
    text-align: center;
  }
  .img-upload-block {
    width: 350px;
    margin-right: 0;
  }
  .composer-main {
    flex-direction: column;
    align-items: center;
  }
}
