/* -------------- Authentication succeeded -------------- */
.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.au-su {
  background-image: url(../../assets/success_bg.png);
  height: calc(100vh - 60px);
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.au-su h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.insert-new-password-wrap {
  background-color: #fff;
  width: 400px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
}
.password-column {
  margin-top: 30px;
}
.password-column input[type="password"] {
  caret-color: grey;
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  padding: 0 0 0 10px;
  outline: none;
}

.password-column button {
  width: 100%;
  height: 50px;
  border: 0;
  margin: 30px 0;
  background-color: var(--pri-color);
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.error-msg {
  height: 40px;
  margin-bottom: 20px;
}
