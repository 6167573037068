/* -------------- Right AD -------------- */
.top {
  height: 60px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  position: fixed;
  left: 250px;
  top: 0;
  width: calc(100% - 250px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  caret-color: transparent;
}

/* No Login */
.top-nologin {
  padding-right: 30px;
}

.top-nologin button {
  display: block;
  background-color: var(--pri-color);
  border-radius: 5px;
  margin-left: 10px;
  padding: 0 20px;
  color: white;
  height: 40px;
  line-height: 40px;
  outline: none;
  border: none;
}

.top-nologin svg {
  margin-right: 10px;
}

.top-nologin button {
  cursor: pointer;
}

.top-nologin button:hover {
  background-color: var(--pri-hover);
}

@media screen and (max-width: 768px) {
  .top-user_dropdown {
    position: static;
    margin-bottom: 20px;
  }

  .top-user_dropdown:before,
  .top-user_dropdown:after {
    display: none;
  }

  .top {
    position: fixed;
    right: -60vw;
    top: 60px;
    width: 60vw;
    left: auto;
    border-bottom: 0;
    height: calc(100vh - 60px);
    align-items: flex-start;
    justify-content: center;
    padding-top: 20px;
    transition: 1s;
  }
  .top.open-topbar {
    right: 0;
  }

  .top-nologin {
    padding-right: 0;
  }

  .top-login {
    flex-direction: column;
    padding-right: 0;
  }

  .top-login .top-userhead {
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .top-login .top-userhead img {
    width: 100px;
    margin-bottom: 10px;
  }

  .top-search {
    position: fixed;
    left: 0;
    top: auto !important;
    bottom: -50px;
    padding-left: 10px;
    width: 60vw;
    z-index: 10;
  }

  .top-search input {
    width: calc(100% - 60px);
  }
  .sign-in-wrap.open-topbar {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
