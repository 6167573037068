nav {
  background-color: #f6f6f6;
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
}

nav .logo {
  display: block;
  cursor: pointer;
}

nav .logo img {
  display: block;
  width: 150px;
  margin: 30px auto 50px;
}

.nav-main {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin: 0 20px;
  font-size: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.nav-main li {
  margin-bottom: 5px;
  display: block;
  line-height: 2.5;
  padding: 0 20px;
  border-radius: 50px;
  cursor: pointer;
}

.nav-main li svg {
  margin-right: 15px;
  line-height: 2.5;
  /* padding: 0 20px; */
  border-radius: 50px;
}

.nav-main li:hover {
  background-color: #e5e5e5;
}

.nav-sub {
  padding-top: 20px;
}

.nav-sub li {
  margin: 0 20px;
}

.nav-sub li a {
  display: block;
  line-height: 2.5;
  padding-left: 30px;
  cursor: not-allowed;
  color: #ccc;
}

.nav-sub li a:hover {
  color: var(--pri-color);
}
nav > svg {
  display: none;
}
@media screen and (max-width: 768px) {
  .rwd-mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  nav {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding: 0 20px;
  }

  nav > svg {
    display: block;
    font-size: 24px;
  }

  nav .logo img {
    height: 36px;
    margin: 0;
  }
  .nav-main.open-navbar {
    left: 0;
    z-index: 10;
  }
  .nav-main {
    position: fixed;
    left: -60vw;
    top: 60px;
    margin: 0;
    width: 60vw;
    height: calc(100vh - 60px);
    padding-top: 20px;
    background-color: #fff;
    transition: 1s;
  }
  .nav-wrap {
    position: fixed;
  }
  .nav-wrap.open-navbar {
    display: block;
    position: fixed;
    top: 60px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .nav-sub {
    position: fixed;
    left: -60vw;
    top: 340px;
    width: 60vw;
    border-top: 1px solid #e5e5e5;
    transition: 1s;
  }
}
