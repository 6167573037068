/* -------------- Right AD -------------- */
.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

/* -------------- Right List -------------- */
.list-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  margin-top: 20px;
}
.composer-item {
  cursor: pointer;
}
.composer-item div {
  width: 180px;
  height: 180px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
}

.composer-item div img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.list li {
  margin-right: 40px;
  margin-bottom: 30px;
}

.list h2 {
  display: block;
}
.list div,
.list h2 {
  text-align: center;
  position: relative;
}
.list div {
  cursor: pointer;
  width: 180px;
}

.list img {
  display: block;
  border-radius: 50%;
  width: auto;
}

.list h2 {
  font-size: 18px;
}

/* Square List */
.list-square img {
  border-radius: 0;
}

.list-square_img-wrap {
  border-radius: 0;
  background-color: #f6f6f6;
  width: 180px;
  height: 180px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.list-square_img-wrap img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.list-square span {
  color: #999;
  display: block;
  font-size: 13px;
}

.list-square svg {
  display: none;
  line-height: 0.5;
  position: absolute;
  left: 90px;
  top: 90px;
  z-index: 10;
  transform: translate(-50%, -50%);
  font-size: 72px;
  color: var(--pri-color);
  background-image: radial-gradient(#fff 20%, transparent);
  border-radius: 50%;
}

.list-square li:hover svg {
  display: block;
}

/* RWD */
@media screen and (max-width: 768px) {
  .top {
    position: fixed;
    right: -60vw;
    top: 60px;
    width: 60vw;
    left: auto;
    border-bottom: 0;
    height: calc(100vh - 60px);
    align-items: flex-start;
    justify-content: center;
    padding-top: 20px;
    transition: 1s;
  }

  .top-nologin {
    padding-right: 0;
  }

  .top-login {
    flex-direction: column;
    padding-right: 0;
  }

  .top-login .top-userhead {
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .top-login .top-userhead img {
    width: 100px;
    margin-bottom: 10px;
  }

  .top-search {
    position: fixed;
    left: 0;
    top: auto !important;
    bottom: -50px;
    padding-left: 10px;
    width: 60vw;
  }

  .top-search input {
    width: calc(100% - 60px);
  }

  .content {
    padding: 0 20px 50px;
    margin-top: 80px;
  }

  .top-user_dropdown {
    position: static;
    margin-bottom: 20px;
  }

  .top-user_dropdown:before,
  .top-user_dropdown:after {
    display: none;
  }

  .composer-item div {
    width: 120px;
    height: 120px;
  }

  .list-title {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .list {
    flex-wrap: nowrap;
    overflow: scroll;
    margin: 0 -20px;
  }
  .list div {
    width: 120px;
  }
  .list li {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .list-square svg {
    left: 60px;
    top: 60px;
    font-size: 48px;
  }

  .list-square_img-wrap {
    border-radius: 0;
    background-color: #f6f6f6;
    width: 120px;
    height: 120px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .list img {
    width: 120px;
  }

  .list h2 {
    font-size: 16px;
    line-height: 1.2;
  }
}
