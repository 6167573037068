.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.signin-wrapper {
  background-image: linear-gradient(-145deg, #fff 0%, #336879 100%);
  background-size: cover;
}

.signin-out {
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 131px);
}

.signin-out h2 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.signin-out > div {
  padding: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 400px;
  /* height: 500px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.signout-email input {
  display: block;
  outline: 0;
}

.signout-email p {
  width: 100%;
}

.signout-email ::placeholder {
  color: #ccc;
}

.signout-email input[type="email"],
.signout-email input[type="password"],
.signout-email input[type="text"] {
  caret-color: grey;
  width: 100%;
  height: 40px;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-left: 10px;
}

.signout-email select {
  font-family: "Raleway", sans-serif;
  padding: 10px;
  height: 40px;
  outline: none;
  border: none;
}

.signout-email input[type="Submit"] {
  width: 100%;
  height: 50px;
  border: 0;
  background-color: var(--pri-color);
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.signout-email input[type="Submit"]:hover {
  background-color: var(--pri-hover);
}

.signout-email ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.signout-email li {
}

.signout-email li a {
  color: #999;
}

.signout-email li a:hover {
  color: var(--pri-color);
}

.signin-wallet {
}

.signin-wallet img {
  width: 70%;
  margin-bottom: 30px;
  filter: grayscale(1);
}

.signin-wallet p {
}

.signin-or {
  font-size: 28px;
  margin: 0 50px;
}
.helper-option {
  margin-top: 15px;
}
.helper-option li {
  color: #999;
  cursor: pointer;
}
.error-msg {
  margin: 10px 0;
  height: 12px;
  font-size: 14px;
  color: red;
  text-align: center;
}
.password-tip p {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .content {
    padding: 0 20px 50px;
    margin-top: 80px;
  }
  .signin-wrapper {
    margin-top: 60px;
    padding-top: 30px;
  }
  .signin-out {
    height: auto;
    flex-direction: column;
  }
}
