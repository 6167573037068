/* -------------- Authentication succeeded -------------- */
.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.au-su {
  background-image: url(../../assets/success_bg.png);
  height: calc(100vh - 60px);
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.au-su img {
  width: 200px;
}

.au-su h1 {
  font-size: 24px;
  font-weight: bold;
}

/* -------------- Verification Code -------------- */
.vf-code {
  width: 400px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
}

.vf-code h1 {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.vf-code p {
  margin-bottom: 30px;
}
.otp-box {
  margin-bottom: 20px;
}

.otp-box p {
  margin-bottom: 10px;
  color: var(--pri-color);
}

.otp-box input[type="text"] {
  width: 40px;
  height: 45px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 24px;
  line-height: 24px;
  margin: 0 5px;
}

.button-panel {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0;
}
.button-panel button {
  width: 100px;
  height: 40px;
  background-color: var(--pri-color);
  border: 0;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.vf-code p {
  text-align: center;
  background-color: transparent;
  color: #333;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
}

.error-msg {
  margin: 10px 0;
  height: 12px;
  font-size: 14px;
  color: red;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .au-su {
    padding: 0 20px;
    margin-top: 60px;
  }
  .vf-code {
    background-color: #fff;
    width: 95%;
  }
  .otp-box input[type="text"] {
    width: 12%;
  }
}
