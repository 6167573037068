.content {
  margin-top: 60px;
  padding: 1px 30px 70px;
}

.forget-password-wrap {
  background-image: linear-gradient(-145deg, #fff 0%, #336879 100%);
  background-size: cover;
}
.forget-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 131px);
}
.forget-password h2 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}
.forget-password > div {
  padding: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.forget-password > div input[type="email"] {
  caret-color: grey;
  width: 100%;
  height: 40px;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-left: 10px;
  display: block;
  outline: none;
}
.forget-password > div input[type="email"]::placeholder {
  color: #ccc;
}
.input-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 30px 0;
}

.input-column button {
  width: 100%;
  height: 50px;
  border: 0;
  background-color: var(--pri-color);
  color: white;
  border-radius: 50px;
  cursor: pointer;
}
.hint {
  line-height: 30px;
  height: 30px;
}
.forget-password p {
  font-size: 13px;
  color: #999;
  text-align: center;
}
