.top {
  height: 60px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  position: fixed;
  left: 250px;
  top: 0;
  width: calc(100% - 250px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  caret-color: transparent;
}

.top-login {
  padding-right: 20px;
  display: flex;
}

.top-login .top-userhead {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}

.top-login .top-userhead img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.top-login .top-userhead .top-user-img-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.top-upload {
  background-color: var(--pri-color);
  color: white;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}

.top-upload img {
  margin-right: 10px;
}

.composer-out {
  width: 1020px;
  margin: 0 auto;
}

.composer-out h1 {
  font-size: 52px;
  line-height: 3;
}

.composer-main {
  display: flex;
}

/* File upload */
.file-upload-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.file-upload-btn {
  display: block;
  padding: 0 20px;
  height: 40px;
  white-space: nowrap;
  background-color: var(--pri-color);
  color: white;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

.file-upload-block p {
  width: calc(60% - 10px);
  height: 40px;
  line-height: 40px;
  color: #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Image upload */
.img-upload-block {
  margin-right: 20px;
  width: 400px;
}

.img-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  border: 1px dashed #e5e5e5;
  background-color: #f6f6f6;
  text-align: center;
  color: #999;
}

.img-upload img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 768px) {
  .top-user_dropdown {
    position: static;
    margin-bottom: 20px;
  }

  .top-user_dropdown:before,
  .top-user_dropdown:after {
    display: none;
  }
  .top.open-topbar {
    right: 0;
  }
  .top {
    position: fixed;
    right: -60vw;
    top: 60px;
    width: 60vw;
    left: auto;
    border-bottom: 0;
    height: calc(100vh - 60px);
    align-items: flex-start;
    justify-content: center;
    padding-top: 20px;
    transition: 1s;
  }

  .top-nologin {
    padding-right: 0;
  }

  .top-login {
    flex-direction: column;
    padding-right: 0;
  }

  .top-login .top-userhead {
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .top-login .top-userhead img {
    width: 100px;
    margin-bottom: 10px;
  }

  .top-upload {
  }

  .top-search {
    position: fixed;
    left: 0;
    top: auto !important;
    bottom: -50px;
    padding-left: 10px;
    width: 60vw;
  }

  .top-search input {
    width: calc(100% - 60px);
  }
  .upload-wrap.open-topbar {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
