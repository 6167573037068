/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body{
  font-family: 'Raleway', sans-serif;
  color: #333;
  line-height: 1.6;
  /* background-image: url(../../assets/html_bg_landing.png); */
}

:root{
  --pri-color: #c30d22;
}

.landing-wrapper {
  width: 100vw;
  background: url(../../assets/html_bg_landing.png) ;
}

.wrapper {
  background-color: #fff;
  max-width: 1600px;
  margin: 0 auto;
}

/* ----------------------- Popup ---------------------- */
#JPO{
  background-color: #fff;
  max-width: 500px;
  padding: 50px 30px;
  position: relative;
  transform: scale(0.8);
}
.popup_visible #JPO {
  transform: scale(1);
}

#JPO h3{
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--pri-color);
}

#JPO i{
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 24px;
  color: #ccc;
}

#JPO i:hover{
  color: #333;
}


/* ----------------------- Header ---------------------- */
header{
  background-image: url(../../assets/header_bg.png);
  background-size: cover;
  height: 90svh;
}

.hd-top{
  padding-top: 85px;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.hd-top img{
  width: 60px;
}

.hd-top i{
  display: none;
}

.hd-top ul{
  display: flex;
}

.hd-top li{
  margin-left: 25px;
}

.hd-top li p,.hd-top li a{
  color: white;
  font-size: 18px;
  line-height: 40px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.hd-top li p:hover, .hd-top li a:hover{
  border-bottom: 3px solid white;
}

.hd-content{
  padding-top: 180px;
  max-width: 1170px;
  margin: 0 auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hd-content i{
  display: none;
}

/* left title */
.hd-content_title{
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hd-content_title h1{
  font-size: 65px;
  font-weight: bold;
  line-height: 105px;
  text-align: center;
  width: 550px;
  border: 3px solid rgba(255,255,255,.5);
  border-width: 3px  0;
}

.hd-content_title h2{
  font-size: 25px;
  margin-top: 10px;
  text-align: center;
}

.hd-enter{
  background-color: var(--pri-color);
  width: 190px;
  line-height: 60px;
  border: 0;
  border-radius: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  margin-top: 100px;
  border: 3px solid transparent;
}

.hd-enter:hover{
  border-color: white;
}


/* right form */
.hd-content_form{}

.hd-content_form h3{
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.hd-content_form ol{}

.hd-content_form li{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.hd-content_form .error{
  color: red;
  font-size: 12px;
  padding-left: 10px;
}

.hd-content_form ol li:last-child{
  margin-top: 20px;
}

.hd-content_form input{
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
}

.hd-content_form li:first-child > label{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.hd-content_form li:first-child > label:hover{
  background-color: var(--pri-color);
  border-color: var(--pri-color);
}

.hd-content_form b{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 15px;
}

.hd-content_form .write{
  width: 190px;
}

.hd-content_form .listen{
  width: 225px;
  margin-left: 20px;
}

.hd-content_form input[type="radio"]{
  display: none;
}

.hd-content_form input[type="text"]{
  width: 100%;
  height: 65px;
  border-radius: 10px;
  border: 0;
  padding-left: 18px;
  box-sizing: border-box;
}

.hd-content_form input[type="radio"]:checked ~ b:before, .hd-content_form label:hover b:before{
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
}

.hd-content_form input[type="submit"]{
  background-color: var(--pri-color);
  width: 190px;
  height: 60px;
  border: 0;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.hd-content_form_discord{
  font-size: 14px;
  width: 230px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.hd-content_form_discord input{
  display: none;
}

.hd-content_form_discord p{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border: 2px solid white;
  border-radius: 5px;
  margin-right: 10px;
}

.hd-content_form_discord p i{
  font-size: 16px;
  display: none;
}

.hd-content_form_discord input:checked ~ p i{
  display: block;
}

.hd-content_form_discord > i{
  font-size: 32px;
  margin-right: 10px;
}

/* ----------------------- About Us ---------------------- */
.about{
  background-image: url(../../assets/about_bg.png);
  background-size: cover;
  color: white;
}

.about-content{
  max-width: 1170px;
  margin: 0 auto;
  padding: 45px 0 55px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-content h3{
  font-size: 36px;
  flex-basis: 100%;
  text-align: center;
}

.about-content img{
  width: 170px;
  margin-right: 110px;
}

.about-content p{
  flex-basis: 800px;
}
.about-content p span{
  display: block;
  margin-bottom: 25px;
}
.about-content p span:last-child{
  margin-bottom: 0;
}

.about-content b{
  color: yellow;
}

/* ----------------------- Blocks ---------------------- */
.main-block{}

.main-block-content{
  width: 1170px;
  height: 430px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.main-block-content img{
  width: 300px;
  position: relative;
  top: 130px;
  flex-shrink: 0;
  margin-left: 50px;
  margin-right: 130px;
}

.main-block-content_text{}

.main-block-content_text h3{
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 1.4;
}

.main-block-content_text span{
  color: var(--pri-color);
  display: block;
}

.main-block-content_text p{}

.main-block-content_text b{
  color: orange;
  display: block;
  font-size: 36px;
  margin-bottom: 20px;
  font-style: italic;
}

.block-gray{
  background-color: #f6f6f6;
}

.block-gray img{
  order: 2;
  margin-right: 50px;
  margin-left: 130px;
}

.block-gray .main-block-content_text{
  order: 1;
  margin-left: 50px;
}

/* ----------------------- Footer ---------------------- */
footer{
  background-color: #222;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 12px;
}

footer ul{
  display: flex;
  margin-bottom: 20px;
}

footer li{
  padding: 0 15px;
  position: relative;
}

footer li:not(:last-child):before{
  content: '';
  display: block;
  width: 1px;
  height: 14px;
  background-color: #aaa;
  position: absolute;
  right: 0;
}

footer a{
  color: #aaa;
}

footer a:hover{
  color: var(--pri-color);
}




/* ---------------------------- Wavv Soft-launch Party Popup ------------------------- */
.wsl-mask{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
}

.wsl-out{
  max-height: 80%;
  padding: 30px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wsl-out img{
  max-width: 450px;
  margin-bottom: 30px;
  border-bottom: 5px solid var(--pri-color);
}

.wsl-content{}

.wsl-out h1{
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
}

.wsl-out h2{
  font-size: 28px;
  text-align: right;
  color: var(--pri-color);
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.wsl-out p{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wsl-out b{}

.wsl-out a{
  display: block;
  background-color: var(--pri-color);
  color: white;
  line-height: 3;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
}

.wsl-out i{
  position: absolute;
  right: -50px;
  top: 0px;
  background-color: #fff;
  font-size: 32px;
  width: 50px;
  height: 50px;
  color: #333;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.wsl-out i:hover{
  background-color: var(--pri-color);
  color: white;
}

@media screen and (max-width: 1440px) {

  header{
    padding-bottom: 30px;
  }

  .hd-top{
    padding-left: 70px;
    padding-right: 70px;
  }
  
  .hd-content{
    padding-top: 200px;
  }

  .about-content h3{
    margin-bottom: 50px;
  }

  .about-content img{
    margin-right: 0;
  }

  .about-content p{
    flex-basis: auto;
    padding: 50px 70px 0;
  }

  .main-block-content{
    width: auto;
  }

}

@media screen and (max-width: 576px) {

  #JPO{
    margin: 0 10px;
  }

  header{
    background-image: linear-gradient(rgba(0,0,0,.8), transparent 50%), url('../../assets/header_bg.png');
    background-position: center center;
    background-size: cover;
    padding: 0 20px 30px;
  }

  .hd-top{
    padding: 20px;
    align-items: center;
  }

  .hd-top img{
    width: 50px;
  }

  .hd-top i{
    display: block;
    display:flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 32px;
  }
  
  .hd-top ul{
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    margin: auto;
    width: calc(100vw - 20px);
    flex-direction: column;
    z-index: 10;
  }
  .hd-top ul.show{
    display: block;
  }
  .hd-top ul.hide{
    display: none;
  }

  .hd-top ul li{
    margin-left: 0;
    border-bottom: 1px solid #333;
    /* background-color: rgba(255,255,255,.9); */
    background-color: #fff;
  }

  .hd-top ul a, .hd-top ul p{
    text-align: center;
    color: #333;
    line-height: 60px;
  }

  .hd-top ul a:hover{
    border-bottom: 0;
  }

  .hd-content{
    padding-top: 0px;
    height: 100%;
    position: relative;
  }

  .hd-content i{
    display: block;
    font-size: 36px;
    width: 27px;
    animation: arr 1s infinite;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15svh;
    margin: auto;
  }

  @keyframes arr {
    0%{
      opacity: 0;
      transform: translateY(0);
    }
    100%{
      opacity: 1;
      transform: translateY(30px);
    }
  }

  .hd-enter{
    margin-top: 0;
    margin-bottom: 100px;
  }

  .hd-content_title{
    margin-bottom: 100px;
  }

  .hd-content_title h1{
    width: auto;
    font-weight: 700;
  }

  .hd-content_title h2{
    font-size: 5.5vw;
  }

  .hd-content_form{
    width: 100%;
  }

  .hd-content_form ol{
    /* display: none; */
  }

  .hd-content_form .write{
    width: 45%;
  }

  .hd-content_form .listen{
    width: 55%;
    margin-left: 10px;
  }

  .hd-content_form b{
    width: 26px;
    height: 26px;
  }

  .hd-content_form input[type="submit"]{
    width: 160px;
  }

  .about{
    height: auto;
    background-size: cover;
  }

  .about-content{
    width: auto;
    padding: 50px 20px;
    flex-direction: column;
  }

  .about-content img{
    order: -1;
    margin-bottom: 30px;
    width: 150px;
  }

  .about-content h3{
    margin-bottom: 0;
  }

  .about-content p{
    flex-basis: auto;
    padding: 0;
  }

  .main-block{}

  .main-block-content{
    height: auto;
    flex-direction: column;
    padding: 50px 20px;
  }

  .main-block-content img{
    margin: 0;
    top: 0;
    width: 200px;
    margin-bottom: 20px;
  }

  .main-block-content_text h3{
    font-size: 26px;
    line-height: 1.2;
  }

  .main-block-content_text span{
    display: block;
  }

  .block-gray img{
    order: 1;
  }

  .block-gray .main-block-content_text{
    margin-left: 0;
  }

  /* ---------------------------- Wavv Soft-launch Party Popup ------------------------- */
  .wsl-out{
    width: 70%;
  }

  .wsl-out img{
    max-width: 100%;
    height: 50vh;
    margin-bottom: 20px;
  }

  .wsl-out h1{
    font-size: 24px;
  }

  .wsl-out h2{
    font-size: 18px;
  }

  .wsl-out p{
    flex-direction: column;
  }

  .wsl-out i{
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 24px;
    border: 1px solid #333;
  }

}


@media screen and (max-width: 375px) {

  header{
    height: auto;
  }

  .hd-content_title h2{
    font-size: 18px;
  }

  .hd-content_form_discord{
    font-size: 12px;
  }

  .hd-content_form input[type="submit"]{
    width: 150px;
  }

  .hd-content_form b{
    width: 20px;
    height: 20px;
  }

  .wsl-out h1{
    font-size: 22px;
  }

  .wsl-out p{
    font-size: 13px;
    margin-bottom: 10px;
  }
}